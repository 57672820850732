import Button from "@/components/Common/Buttons/Button/Button";
import Input from "@/components/Common/Inputs/Input/Input";
import FileInput from "@/components/Common/Inputs/FileInput/FileInput";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import YesNoInput from "@/components/Common/Inputs/YesNoInput/YesNoInput";
import MakePremium from "@/components/Components/MakePremium/MakePremium/";
import PremiumAdSelection from "@/components/Components/PremiumAdSelection/PremiumAdSelection/";
import AdminVendorListProduct from "@/components/Components/AdminVendorListProduct/AdminVendorListProduct/";
import CreditWallet from "@/components/Components/CreditWallet/CreditWallet/";
import AdUpdate from "@/components/Components/AdUpdate/AdUpdate/";
import Pagination from "@/components/Components/Pagination/Pagination/";
import UserTypeMobile from "@/components/Header/HeaderMobile/UserTypeMobile/UserTypeMobile";
import UserNavMobile from "@/components/Header/HeaderMobile/UserNavMobile/UserNavMobile";


export default {
    name: 'AdminVendorSingleOrder',
    components: {

        Button,
        CheckBox,
        MakePremium,
        AdUpdate,
        PremiumAdSelection,
        CreditWallet,
        AdminVendorListProduct,
        TextArea,
        Dropdown,
        Input,
        UserTypeMobile,
        UserNavMobile,
        YesNoInput,
        FileInput,
        Pagination
    },
    data() {
        return {
            order: {},
            filterBoxActive: false,
            activeInactiveSwitch: 'active',
            translation: {
                order_number: 'Поръчка №',
                date: 'Дата',
                name: 'Име',
                order_id: 'Поръчка №',
                phone: 'Телефон',
                status: 'Статус',
                order_details: 'Данни за поръчка',
                filter_by: 'Филтрирай по статус',
                choose_status: 'Изберете статус',
                order_done: 'Приключена',
                order_printed: 'Принтирана',
                add_offer: 'Добави обява',
                email: 'Email',
                active: 'Активни',
                inactive: 'Неактивни',
                client: 'Получател',
                invoice_details: 'Данни за фактуриране',
                delivery: 'Доставка',
                company: 'Фирма',
                address: 'Адрес',
                company_id: 'ЕИК',
                company_vat: 'ИД по ЗДДС',
                point_of_contact: 'МОЛ',
                payment_method: 'Метод на плащане',
                ordered_products: 'Поръчанни продукти',
                product_number: 'арт. №',
                price: 'Цена',
                product: 'Продукт',
                quantity: 'Количество',
                order_status: 'Статус на поръчката',
                printed: 'Принтирана',
                done: 'Завършена',
                save: 'Запази'
            },
            orderStatusOptions: {'Получена': 0, 'Oчаква плащане': 1, 'Платена': 2, 'Доставена': 3}
        }
    },
    methods: {
        toggleFilterBox() {
            this.filterBoxActive = !this.filterBoxActive
        },
        selectOption(option) {
            this.toggleFilterBox();
            this.selectedOption = option;
        },
        async updateOrderStatus() {
            let status = 0;
            if (this.orderStatusOptions[this.order.status]) {
                status = this.orderStatusOptions[this.order.status];
            }

            this.order = await this.$store.dispatch('order/updateOrderStatus', {
                id: this.order.id,
                status: status
            });

            this.order = await this.$store.dispatch('order/getOrder', this.order.id);
        }
    },
    async mounted() {
        const id = this.$route.params.id;
        this.order = await this.$store.dispatch('order/getOrder', id);
    },
}
